//
// Breadcrumb
//

.breadcrumb-item {
    font-size: $font-size-lg;

    font-weight: 700;

    &.active {
        font-weight: 500;
    }
}

.breadcrumb-dark {
    background-color: $breadcrumb-dark-bg;

    .breadcrumb-item {
        font-weight: 600;

        a {
            color: $breadcrumb-dark-color;

            &:hover {
                color: $breadcrumb-dark-hover-color;
            }
        }

        + .breadcrumb-item {
            &::before {
                color: $breadcrumb-dark-divider-color;
            }
        }

        &.active {
            color: $breadcrumb-dark-active-color;
        }
    }
}


// Links

.breadcrumb-links {
    padding: 0;
    margin: 0;
    background: transparent;
}
