$green: #6b6800;
$red: #ff0000;
.val-card {
	margin: 10px auto;
	max-width: 20em;
	min-height: 80%;
	color: #6b6800;
	border-radius: 12px;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
	transition: all 0.3s;

	&:hover {
		transform: scale(1.03);
		background-color: rgb(226, 223, 223);
	}
	&:active {
		transform: scale(0.98);
	}

	.value {
		font-size: 3em;
		text-align: center;
		font-weight: bold;
		position: relative;
		top: 10px;
		&.val-alert {
			color: #ff0000;
		}
		&.val-warning {
			color: #f2ae0c;
		}
	}
	.title {
		font-size: 0.9rem;
		font-weight: bolder;

		color: $green;
		text-align: center;
	}
}
