.btn-save, .btn-edit, .btn-cancel, .btn-add  {
    border: 1px solid #dee2e6 !important;
    margin-right: 11px ;
    font-size: 15px !important;
    padding: 5px !important;
}
.btn-edit {
    margin-right: 1rem !important;
    width:140px ;
}
.btn-date {
    padding:0 !important;
    margin-right: 1rem !important;
    margin-top: 5px !important;
}
.btn-save {
    width:100px !important;
}
.btn-cancel {
    width: 100px;
}
.btn-refresh {
    margin-right: 10px;
    padding:0 !important;
    margin-top: 5px !important;
    width: 120px !important;
    border: 1px  !important;
}
.btn-add {
    border: 1px dashed !important;
}
.page-title {
    padding-top: 5px !important;
    font-size: 15px !important;
    font-size: 16px !important;
    margin-bottom: 0 !important;
    background-color: #f8f9fe !important;
}
.p-breadcrumb {
    border:none !important;
    box-shadow: none !important;
    padding:0 !important;
    background-color: #f8f9fe !important;
}
/* .page-container {
    background-color: #ffff;
    border: 1px solid #dee2e6 !important;
    padding:10px !important;
} */
.activated {
    color:white !important;
    background-color: #2065ac !important;
}
.bread-container {
    padding-left: 20px ;
}
.p-breadcrumb ul {
    background-color:#f8f9fe ;
}
.date-ranger {
    font-size: 15px !important;
}
.refresh-pickers {
    font-size: 15px !important;
    padding:0 !important;
}

.date-range-picker {
    padding:5px !important;
    height: 100% !important;
}

.p-inputtext {
    padding:5px !important;
    font-size: 15px !important;
}

.p-menuitem-text {
    color:#525f7f !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.btn-edit > i {
    transform: translateY(15%) !important;
}
.btn-save > i {
    transform: translateY(10%) !important;
    font-size: 22px !important;
    margin-right: 0.5rem !important;
}

.btn-cancel > i {
    transform: translateY(15%) !important;
    font-size: 22px !important;
    margin-right: 0.5rem !important;
    color:#2065ac;
}

.btn-add > i {
    transform: translateY(15%) !important;
    font-size: 22px !important;
    margin-right: 0.5rem !important;
    color:#2065ac;
}

@media screen and (max-width:768px) {
   .btn-container {
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
   }
   .btn-container-1 {
    display: flex;
    justify-content: center !important;
    margin-top: 10px!important;
    padding-left: 10px !important;
   }
   .bread-container {
    padding-left: 0 !important;
   }
   .page-title {
    display: flex;
    justify-content: center;
    padding-left: 0 !important;
    width: 100%;
   }
   .btn-container-2 {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
   }
   .btn-date{
    max-width: 275px !important;
    margin-top: 10px !important;
    margin-right: 0 !important;
   }
   .btn-refresh {
    margin-top: 10px !important;
   }
}
/* @media screen and (max-width:535px) {
    .btn-date {
        margin-left: 115px !important;
    }
    .btn.btn-refresh {
        margin-left: 105px !important;
    }
    .btn-container-2 {
        padding-right: 100px !important;
    }
    .date-range-picker {
        min-width: 268px !important;
    }
} */