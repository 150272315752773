.refresh-picker {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  .p-button.p-button-outlined.p-button-custom {
    background-color: #fff;
    color: #7b8087;
    border: 1px solid #c7d0d9;

    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
	padding: 10px;
  }

  .p-dropdown {
	width: 100%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .p-inputtext {
	  align-self: center;
  }
}
