.overview-container {
	font-size: 1rem;

	.wrap {
		display: flex;
		margin-bottom: 17px;
	}
	.info-detail {
		font-size: 1.7rem;
		line-height: 3;
		text-align: start;
		padding-left: 10px;
	}
	.hidden-text {
		font-size: 1.7rem;
		line-height: 3;
		visibility: hidden;
	}
	@media screen and (max-width: 991px) {
		.hidden {
			height: 0;
		}
	}
	.left {
		border-right: 1px solid rgba(0, 0, 0, 0.12);
	}
	.col {
		display: flex;
		justify-content: space-between;
		line-height: 1.8;
	}
	.col:nth-child(2n-1) {
		background-color: #f3faff;
	}
}
