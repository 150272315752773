.panel {
  height: 100%;
}

.panel-height-helper {
  display: block;
  height: 100%;
}

.panel-container {
  background-color: $panel-bg;
  border: $panel-border;
  position: relative;
  border-radius: 3px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  box-shadow: $panel-box-shadow;

  &--transparent {
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;
  }

  &:hover {
    .panel-menu-toggle {
      visibility: visible;
      transition: opacity 0.1s ease-in 0.2s;
      opacity: 1;
    }
  }

  &--is-editing {
    height: auto;
  }

  &--absolute {
    position: absolute;
  }
}

.panel-content {
  padding: $panel-padding;
  width: 100%;
  flex-grow: 1;
  height: calc(100% - #{$panel-header-height});

  &--no-padding {
    padding: 0;
  }
}

div.flot-text {
  color: $text-color !important;
}

.dashboard-solo {
  .footer,
  .sidemenu {
    display: none;
  }
}

.template-variable {
  color: $variable;
}

.panel-solo {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: '100%';
  height: '100%';

  .panel-container {
    border: none;
  }

  .panel-menu-toggle,
  .panel-menu {
    display: none;
  }
}
