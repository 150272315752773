$panel-header-no-title-zindex: 1;

.panel-header {
  &:hover {
    transition: background-color 0.1s ease-in-out;
    background-color: $panel-header-hover-bg;
  }
}

.panel-container--no-title {
  .panel-header {
    position: absolute;
    width: 100%;
    z-index: $panel-header-z-index;
  }
  .panel-content {
    height: 100%;
  }
}

.panel-title-container {
  cursor: move;
  word-wrap: break-word;
  display: block;
}

.panel-title {
  border: 0px;
  font-weight: $font-weight-semi-bold;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  height: $panel-header-height;
  line-height: $panel-header-height;
  align-items: center;
}

.panel-title .panel-title-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: -webkit-calc(100% - 38px);
  max-width: calc(100% - 38px);
  cursor: pointer;
  font-weight: 500;
}

.panel-menu-container {
  width: 0px;
  height: 19px;
  display: inline-block;
}

.panel-menu-toggle {
  position: absolute;
  top: calc(50% - 9px);
  color: $text-color-weak;
  cursor: pointer;
  margin: 2px 0 0 2px;
  visibility: hidden;
  opacity: 0;

  &:hover {
    color: $link-hover-color;
  }
}

.panel-loading {
  position: absolute;
  top: 0px;
  right: 4px;
  z-index: $panel-header-z-index + 1;
  font-size: $font-size-lg;
  color: $text-color-weak;

  &:hover {
    cursor: pointer;
  }
}

.panel-empty {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  p {
    text-align: center;
    color: $text-muted;
    font-size: $font-size-lg;
    width: 100%;
  }
}

.panel-menu {
  top: 25px;
  left: -100px;
}

.panel-info-corner-inner {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  bottom: 0;
}

@mixin panel-corner-color($corner-bg) {
  .panel-info-corner-inner {
    border-left: $panel-header-height solid $corner-bg;
    border-right: none;
    border-bottom: $panel-header-height solid transparent;
  }
}

.panel-info-corner {
  color: #7b8087;
  cursor: pointer;
  position: absolute;
  display: none;
  left: 0;
  width: $panel-header-height;
  height: $panel-header-height;
  z-index: $panel-header-no-title-zindex + 1;
  top: 0;

  .fa {
    position: relative;
    top: -2px;
    left: 6px;
    font-size: 75%;
    z-index: $panel-header-no-title-zindex + 2;
	color: #545557;
  }

  .mdi {
    position: relative;
    top: -2px;
    left: 6px;
    font-size: 75%;
    z-index: $panel-header-no-title-zindex + 2;
	color: #545557;
  }

  &--info {
    display: block;
    @include panel-corner-color(lighten(#d9dfe5, 6%));

    .fa:before {
      content: '\f129';
    }

    .mdi:before {
      content: "\F03CC";
    }
  }

  &--links {
    display: block;
    @include panel-corner-color(lighten(#d9dfe5, 6%));
    .fa {
      left: 4px;
    }
    .fa:before {
      content: '\f08e';
    }

	.mdi {
		left: 4px;
	}
	.mdi:before {
		content: "\F03CC";
	  }
  }

  &--error {
    display: block;
    color: $white;
    @include panel-corner-color($popover-error-bg);
    .fa:before {
      content: '\f12a';
    }
  }
}

.panel-info-content {
  a, span {
    color: $gray-6;
	text-decoration: underline;

    &:hover {
      color: darken($white, 10%);
    }
  }

  span {
	  cursor: pointer;
  }

  code {
    white-space: normal;
    word-wrap: break-word;
  }

  pre > code {
    display: block;
  }

  .panel-info-corner-links {
    list-style: none;
    padding-left: 0;
  }
}

.panel-time-info {
  font-weight: $font-weight-semi-bold;
  float: right;
  margin-right: 8px;
  color: $blue;
  font-size: 85%;
  position: absolute;
  right: 0;
}
