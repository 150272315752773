@import "variables";

.p-component {
  font-family: $font-family;
  font-weight: 400;
  font-size: 1rem !important;
}

.table-header > .p-input-icon-left > input.p-inputtext.p-component {
  padding-left: 40px !important;
}