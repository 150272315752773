.date-range-picker {
  display: flex;
//   justify-content: center;
  align-items: center;
  background: #fff;
  color: #7b8087;
  padding: 1rem;
  line-height: 1;
  border-radius: 4px;
  border-width: 0 1px 4px;
  border: 1px solid #c7d0d9;
  cursor: pointer;
  width: 100%;
  justify-content: center;

  .caret {
      position: relative;
	  margin-left: 1rem;
  }

  &.pull-right {
    float: right!important;
  }

  &.pull-left {
    float: left!important;
  }

  span {
    padding-left: 1rem;
	font-size: 1rem;
  }
}

.daterangepicker td.active, .daterangepicker td.active:hover {
	background-color: var(--primary-color);
}

.daterangepicker .ranges li.active {
	background-color: var(--primary-color);
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 4px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
