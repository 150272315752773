.prime-steps {
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #2065AC !important;
    color: #ffffff !important;
  }
  
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    height: 3rem !important;
    width: 3rem !important;
    font-size: 1.5rem !important;
    min-width: 3rem !important;
  }
}

@media screen and (max-width: 640px) {
  .prime-steps .p-steps {
      height: 150px;
  }
  .prime-steps .p-steps > ul {
      flex-direction: column;
      height: 100%;
  }
  .prime-steps .p-steps > ul .p-steps-item {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: center;
  }
  .prime-steps .p-steps > ul .p-steps-item:before {
      position: static;
      left: auto;
      top: auto;
      margin-top: 0;
      border-left: 1px solid var(--surface-d);
      border-top: 0 none;
      width: auto;
      height: 100%;
      margin-left: 1rem;
  }
  .prime-steps .p-steps > ul .p-steps-item .p-menuitem-link {
      flex-direction: row;
      overflow: visible;
  }
  .prime-steps .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
      margin: 0 .5rem 0;
  }
  .prime-steps .p-steps > ul .p-steps-item:last-child {
      flex-grow: 0;
  }
  .prime-steps .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
      padding: 0;
  }
  .prime-steps .p-steps > ul .p-steps-item:last-child:before {
      display: none;
  }
}