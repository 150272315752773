.card {
	.mdi-brightness-1 {
		font-size: 1rem;

		color: green;

		&.icon-alert {
			color: red;
		}
		&.icon-warning {
			color: #f2ae0c;
		}
	}
	.mdi-magnify {
		line-height: 0;
		position: relative;
		top: 4px;
	}

	.p-inputtext:enabled:focus {
		box-shadow: none;
	}

	.p-inputgroup-addon:first-child,
	.p-inputgroup button:first-child,
	.p-inputgroup input:first-child,
	.p-inputgroup > .p-inputwrapper:first-child,
	.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;
		background-color: #dadada;
		&:hover {
			cursor: pointer;
		}
	}

	.p-inputgroup-addon:last-child,
	.p-inputgroup button:last-child,
	.p-inputgroup input:last-child,
	.p-inputgroup > .p-inputwrapper:last-child,
	.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
		border-top-right-radius: 42px;
		border-bottom-right-radius: 42px;
	}

	.btn-group-table {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	.btn-table {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		color: #6b6800;
		background-color: transparent;
		border: none;
		padding: 0px 15px;
		&:hover {
			transform: scale(1.03);
			background-color: rgb(226, 223, 223);
		}
		&:active {
			transform: scale(0.98);
		}
	}
	.header-table {
		margin-bottom: 15px;
	}
}

.table-container {
	background-color: white;
	.button-group {
		position: relative;
		top: 10px;
	}

	//responsive-table

	//  head
	.p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
	.p-datatable.p-datatable-responsive-demo .p-sortable-column.p-highlight {
		padding: 5px;
		background-color: #dadada;
		text-align: center;
	}

	//all table no border
	.p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
	.p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
		border: none;
	}

	//alternating lightblue color
	.p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr:nth-of-type(2n-2) {
		background-color: rgb(198, 220, 228);
	}

	//body
	.p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
		padding: 5px;
		text-align: center;
	}

	.p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
		display: none;
	}

	@media screen and (max-width: 1024px) {
		.p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
		.p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
			display: none !important;
		}

		.p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
			text-align: left;
			display: block;
			width: 100%;
			float: left;
			clear: left;
			border: 0 none;
		}

		.p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
			padding: 0.4rem;
			min-width: 30%;
			display: inline-block;
			margin: -0.4em 1em -0.4em -0.4rem;
			font-weight: bold;
		}

		.p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
			border-bottom: 1px solid var(--surface-d);
		}
	}
}
.expansion-line {
	width: 75%;
	margin: 5px auto;
}

.overview {
	padding-top: 15px;
	padding-bottom: 75px;
	padding-left: 20px;
}

//tab-divider

.expandable-tabs {
	justify-content: space-around;
	border-top: 1px solid #dadada;
}
.tabs-element {
	display: flex;
	flex-direction: column;
	padding: 10px;
	padding-top: 15px;
	border-radius: 15px;
	color: #6b6800 !important;

	cursor: pointer;
	&.active {
		border-radius: 10px;
	}
}
