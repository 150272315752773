$green: #97c33c;
$blue: #2065ac;
$orange: #f2931f;
$grey: #707070;
$red: #d91940;

* {
	box-sizing: border-box;
}
.myCard {
	// max-width: 350px;
	width: 300px;
	max-width: 340px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.totalValue {
		font-size: 1.2rem;
	}

	.titleText {
		margin-bottom: 0.5rem;
		font-family: inherit;
		font-weight: 600;
		line-height: 1.5;
		color: #32325d;
		font-size: 1.2rem;
		&.title-icon {
			position: relative;
			left: 9px;
			bottom: 10px;
		}
	}
	.valueText {
		margin-bottom: 0.5rem;
		font-family: inherit;
		font-weight: 600;
		line-height: 1.5;
		color: #32325d;
		font-size: 2rem;
		&.value-icon {
			position: relative;
			right: 24px;
		}
	}

	.divider {
		margin-bottom: 0.5rem;
		font-family: inherit;
		font-weight: 600;
		line-height: 1.5;
		color: #32325d;
		font-size: 1.8rem;
		margin-left: 0.3rem;
		margin-right: 0.3rem;
	}

	.myCard-value {
		display: flex;
		&.card-text {
			justify-content: center;
			align-items: center;
		}
		&.icon-active {
			position: relative;
			top: 30px;
		}
	}

	.myCard-value,
	.myCard-title {
		&.icon-active {
			margin-top: 5px;
			margin-left: 15px;
		}
	}

	.icon-container {
		width: 35%;
		height: 65%;
		border-right: 1px solid $grey;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0em;
	}
	.icon {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background-color: $blue;
		display: flex;
		justify-content: center;
		// align-items: center;
		position: absolute;
		left: 27px;

		i {
			color: white;
			font-size: 3rem;
			position: relative;
			top: 0.05em;
			transform: rotate(-43deg);
		}
	}
	.line {
		background-color: black;
		width: 100px;
	}

	&.icon-active {
		padding: 10px 0;
		.myCard-value {
			position: relative;
			top: 1.4rem;
		}
	}

	&.success {
		.valueText {
			color: $green;
		}
	}
	&.primary {
		.valueText {
			color: $blue;
		}
	}
	&.warning {
		.valueText {
			color: $orange;
		}
	}
	&.disabled {
		.valueText {
			color: $grey;
		}
	}
	&.danger {
		.valueText {
			color: $red;
		}
	}
}
