$green: #6b6800;
$red: #ff0000;
.icon-card {
	max-width: 20em;
	min-height: 80%;
	margin: 10px auto;
	box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
	transition: all 0.3s;

	&:hover {
		transform: scale(1.03);
		background-color: rgb(226, 223, 223);
	}
	&:active {
		transform: scale(0.98);
	}

	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 3fr 0.5fr;
	row-gap: 5px;
	border-radius: 12px;
	.title {
		grid-column: 1/3;
		text-align: center;
		font-size: 0.9rem;
		font-weight: bolder;
		line-height: 1.5;
		color: $green;
	}
	.icon {
		justify-self: center;
		align-self: flex-start;
	}

	.valueIcon {
		font-size: 4rem;
		line-height: 0.5;
		position: relative;
		top: 7px;
		color: #6b6800;
	}

	.value-group {
		.value {
			font-size: 2rem;
			line-height: 0.8;
			color: $red;
		}
		.machine {
			font-size: 0.7rem;
			color: $green;
		}
	}
	.title {
		position: relative;

		letter-spacing: 1px;
	}
}
