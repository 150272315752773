* {
	box-sizing: border-box;
}
.consumptionCard {
	width: 200px;
	height: 250px;
	max-width: 340px;
	display: flex;
    text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0rem 1rem;
	border-radius: 0;
	background-color: #f7f7f7;

	.totalValue {
		font-size: 1.2rem;
	}

	.siteTitle {
		font-family: inherit;
		font-weight: 600;
		color: #222;
		font-size: .75rem;
	}

	.valueText {
		top: 0;
		font-family: inherit;
		font-weight: 600;
		color: #73BF69;
		font-size: 4rem;
	}

	.myCard-value {
		display: flex;
		&.card-text {
			justify-content: center;
			align-items: center;
		}
	}

}