.bar-card {
	max-width: 40em;
	margin: 10px auto;
	h5 {
		font-size: 1.4em;
		position: relative;
		top: 25%;
	}
	background-color: white;
	border: 1px solid black;
	border-radius: 8px;
	padding: 2em;
	display: grid;
	grid-template-rows: 0.4fr 3fr;
	grid-template-columns: 1fr 1fr;
	row-gap: 1em;
	box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.363);
	.mainChart {
		grid-column: 1/3;
	}
}
