.card-container {
	margin: 10px auto;
	width: 80vw;
	background-color: rgb(202, 224, 231);
	padding: 1em;
	border: 1px solid black;
	border-radius: 10px;
	h3 {
		font-size: 1.5rem;
	}
	.p-inputgroup,
	.p-dropdown,
	.download,
	.filter {
		height: 50%;
	}

	.mdi-magnify {
		font-size: 1em;
	}

	.btn {
		&.download,
		&.filter {
			font-size: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&.add {
			.content,
			i {
				font-size: 0.85rem;
			}
			.content {
				position: relative;
				top: -0.3em;
			}
			background-color: white;
			height: 70%;
			border: 1px solid rgb(211, 210, 210);
			border-radius: 20px;
		}
	}

	i.mdi.mdi-download-box-outline.download-icon,
	.filter-icon {
		font-size: 1.2rem;
	}
}
