.prime-datatable {
  // custom color
  .p-datatable.node-table {
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  
    .p-datatable-thead > tr > th,
    .p-sortable-column.p-highlight {
      background-color: #f7fafc;
    }
  
    .p-datatable-tbody > tr.p-highlight {
      background: unset;
      color: unset;
    }
  
    .p-highlight .p-checkbox .p-checkbox-box {
      border-color: #2196f3;
    }
  
    &.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
      background-color: #f7fafc;
  
      &.p-highlight {
        background: #f7fafc;
        color: unset;
      }
    }
  
    &.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
      background-color: #eee;
  
      td {
        border-color: #fff;
      }
    }
  }
  
  // custom font
  .p-datatable.node-table {
    .p-column-title,
    .p-datatable-tbody > tr > td,
    .p-sortable-column .p-sortable-column-icon {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.87);
    }
  
    .p-column-title {
      font-weight: 500;
    }
  
    .p-datatable-tbody > tr > td {
      font-weight: 400;
      font-size: 13px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  
    .p-datatable-tbody > tr > td {
      font-size: 13px;
    }
  }
  
  // custom checkbox
  .p-datatable.node-table {
    .p-checkbox {
      display: flex;
      align-items: center;
      zoom: 0.8;
  
      .p-checkbox-box {
        border-color: #666;
  
        .p-checkbox-icon {
          -webkit-text-stroke-width: 2px;
        }
      }
    }
  }
  
  // custom sortable icon
  .p-datatable.node-table {
    .p-sortable-column .p-sortable-column-icon {
      margin-left: 2px;
      margin-top: -2px;
    }
  
    .pi-sort-alt:before {
      content: none;
    }
  
    .pi-sort-amount-up-alt:before {
      content: '▲';
      color: rgba(0, 0, 0, 0.87);
    }
  
    .pi-sort-amount-down:before {
      content: '▼';
      color: rgba(0, 0, 0, 0.87);
    }
  }
  
  // custom collapsable row icon and container
  .p-datatable.node-table {
    .pi-chevron-right:before {
      content: '\e902';
    }
  
    .pi-chevron-down:before {
      content: '\e903';
    }
  
    .p-datatable-tbody > tr > td[colspan] {
      background-color: #fff;
      border-color: #fff;
      padding: 0;
    }
  
    &.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
      color: rgba(0, 0, 0, 0.87);
      font-size: 1rem;
    }
  }
  
  // custom pagination
  .p-datatable.node-table {
    .p-paginator {
      border: none;
      font-size: 13px;
  
      * {
        font-size: 13px;
      }
    }
  
    .p-dropdown {
      align-items: center;
      margin-left: auto;
      border: unset;
  
      * {
        border: unset;
      }
  
      &::before {
        position: absolute;
        left: -90px;
        content: 'Rows per page:';
      }
  
      .p-dropdown-trigger {
        width: 1.5rem;
        .pi-chevron-down:before {
          content: '▼';
        }
      }
    }
  }
  
  // responsive
  .p-datatable.node-table {
    .p-datatable-tbody > tr > td .p-column-title {
      display: none;
    }
  
    @media screen and (max-width: 1200px) {
      table {
        table-layout: unset;
      }
  
      .selector {
        width: 0.5rem !important;
        padding: 0 0.5rem !important;
      }
  
      .selector:first-child {
        padding-left: 1rem !important;
      }
  
      .p-datatable-tbody > tr > td:not(.selector):not([colspan]) {
        text-align: left !important;
        display: block;
        float: left;
        clear: left;
      }
  
      .p-datatable-tbody > tr > td {
        border: 0 none;
      }
  
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td,
      .m-none,
      .p-paginator-current,
      .p-dropdown::before,
      .p-paginator-left-content {
        display: none !important;
      }
    }
  }
  
  // hide filter
  .p-datatable.node-table {
    .p-filter-column {
      display: none;
    }
  }
  
  .d-none-custom {
    width: 0;
    height: 0;
    padding: 0 !important;
    overflow: hidden;
    overflow-wrap: normal;
  }
}
