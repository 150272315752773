.calendar {
	width: 80%;
	margin: 10px auto;

	.fc-toolbar {
		height: 3rem;
	}
	.fc {
		display: flex;
		flex-direction: row;
	}

	.fc-button.fc-prev-button,
	.fc-button.fc-next-button,
	.fc-button.fc-button-primary {
		display: inline-block;
		border: none;
		padding: 1rem 2rem;
		margin: 0;
		text-decoration: none;
		background: #ffffff;
		color: black;
		font-family: sans-serif;
		font-size: 1rem;
		cursor: pointer;
		text-align: center;
		transition: background 250ms ease-in-out, transform 150ms ease;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.top-part {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;
	}
}
