@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

.react-resizable-handle {
  // this needs to use visibility and not display none in order not to cause resize flickering
  visibility: hidden;
}

.react-grid-item {
  touch-action: initial !important;

  &:hover {
    .react-resizable-handle {
      visibility: visible;
    }
  }
}

.panel-in-fullscreen {
  .react-grid-item {
    display: none !important;
    transition-property: none !important;

    &--fullscreen {
      display: block !important;
      position: unset !important;
      transform: translate(0px, 0px) !important;
    }
  }

  // Disable grid interaction indicators in fullscreen panels
  .panel-header:hover {
    background-color: inherit;
  }

  .panel-title-container {
    cursor: pointer;
  }

  .react-resizable-handle {
    display: none;
  }

  // the react-grid has a height transition
  .react-grid-layout {
    transition-property: none;
  }
}

@include media-breakpoint-down(sm) {
  .react-grid-item {
    display: block !important;
    transition-property: none !important;
    position: unset !important;
    transform: translate(0px, 0px) !important;
    margin-bottom: $space-md;
  }
}

.react-grid-item.react-grid-placeholder {
  box-shadow: $panel-grid-placeholder-shadow;
  background: $panel-grid-placeholder-bg;
  z-index: 0;
  opacity: unset;
}

.theme-dark {
  .react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid $gray-1;
    border-bottom: 2px solid $gray-1;
  }
}

.theme-light {
  .react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid $gray-3;
    border-bottom: 2px solid $gray-3;
  }
}

// Hack for preventing panel menu overlapping.
.react-grid-item.resizing.panel,
.react-grid-item.panel.dropdown-menu-open,
.react-grid-item.react-draggable-dragging.panel {
  z-index: $zindex-dropdown;
}

// Disable animation on initial rendering and enable it when component has been mounted.
.react-grid-item.cssTransforms {
  transition-property: none;
}

.animated .react-grid-item.cssTransforms {
  transition-property: transform;
}
