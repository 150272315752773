.footer-container {
	display: flex;
	align-items: center;
	justify-content: center;

	.powered-by {
		display: flex;
		align-items: center;
		justify-content: center;

		.powered-text {
			font-size: 14px;
			color: rgb(204, 204, 204);
			margin-right: 10px;
			font-weight: 600;
		}

		.powered-logo {
			width: 150px;
		}
	}

	.vl {
		border-left: 1px solid gray;
		height: 40px;
		margin-left: 10px;
		margin-right: 10px;
	}

	.contact-us {
		display: flex;
		justify-content: center;

		.contact-us-text {
			font-size: 18px;
			color: rgb(204, 204, 204);
			margin-right: 10px;
		}

		.contact-us-icon {
			font-size: 20px;
			color: rgb(204, 204, 204);
		}
	}
}
