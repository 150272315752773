$green: #97c33c;
$blue: #2065ac;
$orange: #f2931f;
$grey: #707070;
$red: #d91940;
$yellow: #F1CE4C;

* {
	box-sizing: border-box;
}
.siteCard {
	// max-width: 350px;
	width: inherit;
	height: inherit;
	// max-width: 340px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// margin: 1rem;
	border-radius: 0;

	&.success {
		background-color: $green;
	}
	&.primary {
		background-color: $blue;
	}
	&.warning {
		background-color: $orange;
	}
	&.disabled {
		background-color: $grey;
	}
	&.danger {
		background-color: $red;
	}
	&.minor {
		background-color: $yellow;
	}


	.totalValue {
		font-size: 2vh;
	}

	.siteTitle {
		font-family: inherit;
		font-weight: 600;
		color: #fff;
		font-size: 0.8rem;
	}

	.valueText {
		font-family: inherit;
		font-weight: 600;
		color: #fff;
		font-size: 2.5rem;
	}

	// .divider {
	// 	margin-bottom: 0.5rem;
	// 	font-family: inherit;
	// 	font-weight: 600;
	// 	line-height: 1.5;
	// 	color: #32325d;
	// 	font-size: 1.8rem;
	// 	margin-left: 0.3rem;
	// 	margin-right: 0.3rem;
	// }

	.myCard-value {
		display: flex;
		&.card-text {
			justify-content: center;
			align-items: center;
		}
	}

	// .myCard-value,
	// .myCard-title {
	// 	&.icon-active {
	// 		margin-top: 5px;
	// 		margin-left: 15px;
	// 	}
	// }

	// .icon-container {
	// 	width: 35%;
	// 	height: 65%;
	// 	border-right: 1px solid $grey;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	position: absolute;
	// 	left: 0em;
	// }
	// .icon {
	// 	width: 80px;
	// 	height: 80px;
	// 	border-radius: 50%;
	// 	background-color: $blue;
	// 	display: flex;
	// 	justify-content: center;
	// 	// align-items: center;
	// 	position: absolute;
	// 	left: 27px;

	// 	i {
	// 		color: white;
	// 		font-size: 3rem;
	// 		position: relative;
	// 		top: 0.05em;
	// 		transform: rotate(-43deg);
	// 	}
	// }
	// .line {
	// 	background-color: black;
	// 	width: 100px;
	// }

	// &.icon-active {
	// 	padding: 10px 0;
	// 	.myCard-value {
	// 		position: relative;
	// 		top: 1.4rem;
	// 	}
	// }
}