.pie-card {
	background-color: white;
	&.menu-active {
		position: relative;
		top: 0em;
	}
	position: relative;
	top: 1em;
}
.pie-grid-container {
	display: grid;
	&.container-menu {
		grid-template-rows: 0.5fr 3fr;
	}
	grid-template-rows: 0.5fr 3fr 0.5fr;

	width: 20em;
	// height: 30em;
	background-color: #fff;
	padding: 30px;
	border: 1px solid rgb(94, 92, 92);
	border-radius: 2%;
	h1 {
		font-size: 1.2em;
		margin-bottom: 1.5em;
	}

	.pie-menu {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		border-top: black 1px solid;
		// position: relative;
		// top: 1.5em;
		margin-top: 1.5em;
	}

	span {
		color: #2065ac;
		margin-top: 1em;
		position: relative;
		top: 0.5em;
		cursor: pointer;
	}
	box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.363);
}
