$blue: #2065ac;
.formPanel-card {
	margin: 2em auto;
	padding: 2em;
	.label-on {
		margin-left: 0.2em;
		margin-right: 1em;
	}
	.p-button {
		background-color: $blue;
		border: 1px solid $blue;
	}
	.radio-button {
		display: flex;
	}
	.buttons {
		display: flex;
		justify-content: flex-end;
		&.cancel {
			margin-right: 1em;
			color: $blue;
			background-color: white;
			border: 1px solid $blue;
		}
		&.submit {
			background-color: $blue;
			border: 1px solid $blue;
		}
		&.cancel,
		&.submit {
			margin-top: 2em;
			width: 5rem;
		}
	}
}

.formPanel-grid-container {
	display: grid;
	grid-template-columns: 1fr 2fr;

	gap: 1em;
	@media only screen and (min-width: 790px) {
		grid-template-columns: repeat(2, 1fr 2fr);
		.buttons {
			grid-column: 4;
		}
	}

	@media screen and (max-width: 480px) {
		grid-template-columns: 1fr;
	}
	label {
		text-align: start;
	}
}

.p-card {
	&.p-card-title {
		font-weight: 400;
	}
}
