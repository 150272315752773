$green: #97c33c;
$blue: #2065ac;
$orange: #f2931f;
$grey: #707070;
$red: #d91940;

* {
	box-sizing: border-box;
}

.myStatusCard {
	width: 300px;
	max-width: 340px;
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-template-rows: 1fr 1fr;
	font-size: 1.4rem;
	padding: 0.9rem;
	.value {
		font-size: 2rem;
	}
	.title {
		position: relative;
		top: -12px;
	}
	.icon {
		background-color: $blue;
		align-self: center;
		grid-row: 1/3;
		border-radius: 50%;
		height: 4rem;
		width: 4rem;
		i {
			font-size: 2.7rem;
			position: relative;
			left: 0.1em;
			top: -0.1em;
			transform: rotate(-43deg);
			color: white;
		}
	}

	.value,
	.title {
		border-left: 1px solid black;
		padding-left: 1rem;
		span {
			transform: translate(0, 25%);
		}
	}
	&.success {
		.value {
			color: $green;
		}
	}
	&.primary {
		.value {
			color: $blue;
		}
	}
	&.warning {
		.value {
			color: $orange;
		}
	}
	&.disabled {
		.value {
			color: $grey;
		}
	}
	&.danger {
		.value {
			color: $red;
		}
	}
}
