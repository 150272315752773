.collapsibleCard {
	min-width: 25rem;
	border-radius: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: var(--secondary);

	.main {
		font-size: 2rem;
		text-align: center;
	}

	.divider {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.collapsible {
		font: size 0.9rem;
		text-align: center;
	}

	.button {
		width: 2rem;
		height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		position: absolute;
		bottom: -15px;
		border-width: 0px;
		background-color: white;
	}
}

.mdi {
	font-size: 20px;
	text-align: center;
}
