.tritronik-logo {
	position: absolute;
	left: 3rem;
	top: 3rem;
}
.tritronik-logo h4 {
	font-size: 1.2vw;
}
.tritronik-logo img {
	width: 15%;
	height: auto;
}
.login-title {
	font-size: 2.5rem;
}
.copyright {
	text-align: center;
	margin-top: 5rem ;
}
@media screen and (max-width: 768px) {
	.tritronik-logo {
		left: 1rem;
		top: 1rem;
	}
	.tritronik-logo img {
		width: 40%;
		height: auto;
	}
	.tritronik-logo h4 {
		font-size: 2.5vw;
		position: absolute;
		right: 1rem;
		top: 0.5rem;
	}
	.form-column {
		margin:0 !important;
		width:100% !important;
	}
	
}
@media screen and (max-width: 992px) {
	.copyright {
		margin-top: 10px !important;
	}
	.logo-title{
		display: block !important;
		max-width:275px !important ;
	}
}
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
    background: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100%;
}
.page-content {
	z-index: 3;
}