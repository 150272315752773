$green: #6b6800;
$red: #ff0000;

.detail {
	display: grid;
	grid-template-rows: 30px 1fr;
	min-width: 20em;
	max-width: 25em;
	height: 100%;
	background-color: white;
	border-radius: 7px;
	padding-bottom: 1em !important;
	&.detail-alert {
		border: 1.5px solid #ff0000;
	}
	&.detail-warning {
		border: 1.5px solid #f2ae0c;
	}

	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	.header {
		display: flex;
		justify-content: space-between;
		max-width: 370px;
		overflow: hidden;
		.node {
			font-size: 1.2rem;
			line-height: 1.5;
			color: #6b6800;
			overflow: hidden;
		}
		.condition-group {
			display: flex;
			align-items: center;
			margin-left: 10px;
			.sign {
				align-self: flex-start;
				max-height: 70%;
				height: auto;
				max-width: 40px;
				width: auto;
			}
			.mdi-alert {
				font-size: 1.2rem;
				line-height: 1.7;
				margin-right: 0.1rem;
				&.condition-alert {
					color: #ff0000;
				}
				&.condition-warning {
					color: #f2ae0c;
				}
			}
			.alert-notif {
				margin-bottom: 0;
				font-size: 1.3rem;
				color: #ff0000;
			}
			.warning-notif {
				margin-bottom: 0;
				font-size: 1.5rem;
				color: #f2ae0c;
			}
		}
	}

	.body {
		display: flex;
		margin-top: 7px;
		justify-content: space-evenly;
		// .picture {
		// 	object-fit: contain;
		// 	width: 5em;
		// 	justify-self: flex-end !important;
		// }

		.bodyIcon {
			font-size: 6rem;
			line-height: 0.7;
			position: relative;
			top: 10px;
			color: #6b6800;
		}

		.body-group {
			display: grid;
			grid-template-columns: 135px 45px;
			grid-template-rows: 1fr 1fr 40px;
			.change {
				display: flex;
			}
			.small-icon {
				font-size: 1rem;
				position: relative;
				top: -5px;
				&.small-alert {
					color: #ff0000;
				}
			}
			.span-val {
				color: #6b6800;
				padding: 0;
				&.-alert {
					color: #ff0000;
				}
			}
			.change-span {
				color: #6b6800;
			}
		}

		.big-icon {
			font-size: 2.7rem;
			color: #ff0000;
			grid-row: 1/3;
			grid-column: 2;
		}
		.title {
			grid-column: 1;
			grid-row: 2;
		}
		.detail-component {
			grid-row: 3;
			grid-column: 1/3;
			display: grid;
			grid-template-columns: 0.7fr 1fr;
		}
	}
	// img {
	// 	padding-right: 10px;
	// }
	// .gasImg {
	// 	width: 3.5em;
	// }
	// .motorImg {
	// 	width: 5em;
	// }
	.value-group {
		font-size: 1.5rem;

		color: #6b6800;
		position: relative;
		top: 7px;

		&.-alert {
			color: #ff0000;
		}
	}
	.title {
		font-size: 1.2rem;
		color: #6b6800;
		position: relative;
		top: 7px;
		display: flex;
	}

	.sign {
		width: 2.5em;
	}
}
