.tritronik-table-panel {
	.p-datatable .p-datatable-thead > tr > th {
		font-size: 1rem;
		padding: 0.75rem 0.75rem;
	}
	.p-datatable .p-datatable-tbody > tr {
		font-size: 1rem;
	}

	.p-datatable .p-datatable-tbody > tr > td {
		padding: 0.75rem 0.75rem;
	}

}

.tritronik-table-panel .p-paginator .p-paginator-current {
    margin-left: auto;
}

.tritronik-table-panel .row-info {
	background-color: #F7FAFC !important;
}
.tritronik-table-panel .row-success {
	background-color: #C8F2C2 !important;
}
.tritronik-table-panel .row-warning {
	background-color: #FFCB7D !important;
}
.tritronik-table-panel .row-danger {
	background-color: #FDF2F2 !important;
}
