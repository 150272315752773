.prime-table {
	.p-paginator .p-paginator-current {
		margin-left: auto;
	}

	.p-progressbar {
		height: 0.5rem;
		background-color: #d8dadc;
	}

	.p-progressbar .p-progressbar-value {
		background-color: #607d8b;
	}

	.table-header {
		display: flex;
		justify-content: space-between;
	}

	.p-datepicker {
		min-width: 25rem;
	}

	.p-datepicker td {
		font-weight: 400;
	}

	.p-datatable.p-datatable-custom {
		.p-datatable-header {
			background-color: #fff;
			border: none;
			padding: 1rem 0;
			text-align: left;
			font-size: 1.5rem;

			.p-input-icon-left > .p-inputtext {
				border-radius: 20px;
				padding-left: 2.5rem !important;
				width: 30rem;
			}

			.p-input-icon-left > i:first-of-type,
			.p-input-icon-left > svg:first-of-type,
			.p-input-icon-left > .p-input-prefix {
				left: 1rem;
				color: #6c757d;
			}
		}

		.p-paginator {
			padding: 1rem;
		}
		.p-paginator-bottom {
			border-width: 0 0;
			border-radius: 0;
		}

		.p-datatable-thead > tr > th {
			text-align: left;
		}

		.p-datatable-tbody > tr > td {
			cursor: auto;
		}

		.p-dropdown-label:not(.p-placeholder) {
			text-transform: uppercase;
		}

		.p-datatable-tbody > tr > td .p-column-title {
			display: none;
		}
	}

	@media screen and (max-width: 960px) {
		.p-datatable {
			&.p-datatable-custom {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					> td {
						text-align: left;
						display: block;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: 0.4rem;
							min-width: 30%;
							display: inline-block;
							margin: -0.4rem 1rem -0.4rem -0.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: 0.5rem;
						}
					}
				}

				colgroup {
					display: none;
				}

				.p-datatable-header {
					.p-input-icon-left > .p-inputtext {
						width: 100%;
					}
				}
			}

			.p-datatable-header {
				.table-header {
					flex-direction: column !important;
				}

				.p-input-icon-left > .p-inputtext {
					border-radius: 20px;
					padding-left: 2.5rem !important;
					width: 100%;
				}
			}
		}
	}
}

.status-badge {
	border-radius: 2px;
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: 0.3px;

	&.status-qualified {
		background: #c8e6c9;
		color: #256029;
	}

	&.status-unqualified {
		background: #ffcdd2;
		color: #c63737;
	}

	&.status-negotiation {
		background: #feedaf;
		color: #8a5340;
	}

	&.status-new {
		background: #b3e5fc;
		color: #23547b;
	}

	&.status-renewal {
		background: #eccfff;
		color: #694382;
	}

	&.status-proposal {
		background: #ffd8b2;
		color: #805b36;
	}
}
